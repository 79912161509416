import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Aligner = makeShortcode("Aligner");
const Video = makeShortcode("Video");
const Link = makeShortcode("Link");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Process = makeShortcode("Process");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "🧩-revealing-the-universe",
      "style": {
        "position": "relative"
      }
    }}>{`🧩 Revealing the universe`}<a parentName="h1" {...{
        "href": "#%F0%9F%A7%A9-revealing-the-universe",
        "aria-label": "🧩 revealing the universe permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Aligner center sx={{
      fontStyle: 'italic'
    }} mdxType="Aligner">
"The overall goal is: show a lot of truth, with minimum contrivance" - Jonathan Blow
    </Aligner>
    <p>{`Andy Matuschak and Michael Nielsen suggested that `}<a parentName="p" {...{
        "href": "/learn/module-3/remember/#extending-our-mnemonics"
      }}>{`transformative tools - especially public goods`}</a>{` -
stood to learn a lot from game designers, who have far more complete emotional and psychological
models of human behaviour than anyone else in the digital world. So, let's take them at their
word and see what the best indie developers in the world have to say about designing valuable games.`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/OGSeLSmOALU?start=91" mdxType="Video" />
    <h3 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h3" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`You may be asking what computer games have to do with token design. Though the universe of games
is fictional, the whole point of this talk is to demonstrate how the truths you will learn in a
valuable game apply in general. So the real question is: what universe do blockchains instantiate?`}</p>
    <p>{`We've already suggested the answer: `}<strong parentName="p">{`a universe of distributed `}<a parentName="strong" {...{
          "href": "/learn/module-0/trust/"
        }}>{`trust`}</a></strong>{`. Now, like any good game designer, we have to find the "nuggets of truth" in this world, and  present them to everyone with the lightest possible contrivance. `}</p>
    <p>{`We could teach you about the different kinds of `}<Link to="https://medium.com/bollinger-investment-group/constant-function-market-makers-defis-zero-to-one-innovation-968f77022159" mdxType="Link">{`constant function market makers`}</Link>{`, but these are just one particular flavour of one kind of nugget within the entire universe of applications made possible by distributed trust. We want to demonstrate `}<em parentName="p">{`the principle`}</em>{` here: `}<strong parentName="p">{`virtuous games lead to valuable truth`}</strong>{`. The better we design our games, the more clearly we can share our truths.`}</p>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Jonathan begins with a description of Portal 2, and demonstrates discovering a game mechanic to
do with using light bridges in such a way that you explore otherwise impossible-to-reach places.
He also shows a classic game - vvvvvv - which allows you to change the direction of gravity for
your character. Out of this single, incredibly simple rule, you can build mechanics that allow
the character to traverse 180 degree bends and full circles, opening the game up into all sorts
of fun and revealing mazes with just one rule. Such mechanics are:`}</p>
    <blockquote>
      <p parentName="blockquote">{`little nuggets of truth the designer has found and packaged for the player. Now, I am calling
them 'truth' even though you, as a player, encounter them as pieces of a fictional game-world
that doesn't exist. But, if you think carefully about these things: they are about subjects
which pertain to the real world.`}</p>
    </blockquote>
    <p>{`These often reveal sophisticated understandings of the universe - like lines intersecting in
2-D space, but being one below the other in 3 dimensions (as in Portal 2), `}<strong parentName="p">{`yet in game play,
it happens very naturally.`}</strong>{` This is a lot like what mathematicians do: they build higher-order
constructs out of primitive "what if?" questions and then `}<strong parentName="p">{`explore the consequences`}</strong>{`.`}</p>
    <p>{`If you learn only one thing this week, let it be this: `}<strong parentName="p">{`mechanism design is about asking the most simple "what if?" question you can, and then exploring the possibilities it implies fully`}</strong>{`.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Virtuous games lead to `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` `}{`_`}{`_`}{`_`}{`_`}{`_`}{`.`}</p>
        <p>{`valuable truth.`}</p>
      </Card>
    </Flash>
    <p>{`Which brings us to the `}<em parentName="p">{`aesthetics of game design:`}</em></p>
    <Process mdxType="Process">
      <p>{`Mine nuggets of truth.`}</p>
      <p>{`Package that truth for the player.`}</p>
    </Process>
    <p>{`Games are both toy universes and complex mathematical systems, and mathematics is an
"unreasonably effective" tool for understanding the universe. In particular, mathematicians
seem to agree that the shortest theorems which carry the deepest consequences are the "most
beautiful." These are the nuggets we're looking for in the design of any mechanism.`}</p>
    <h3 {...{
      "id": "exploring-well",
      "style": {
        "position": "relative"
      }
    }}>{`Exploring well`}<a parentName="h3" {...{
        "href": "#exploring-well",
        "aria-label": "exploring well permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Process mdxType="Process">
      <p>{`Start with a mechanic; like a grappling hook that you can shoot and pull yourself with. `}</p>
      <p>{`Or be more vague, beginning with a detail or consequence without being sure of the framework
around it. For instance: a puzzle game where you know that you want players to remember a melody
and recite it back at some stage, but don't know exactly how.`}</p>
      <p>{`Aim towards the `}<strong parentName="p">{`richest space`}</strong>{`, explore it `}<strong parentName="p">{`completely`}</strong>{`, and trace a `}<strong parentName="p">{`strong boundary`}</strong>{`
around it.`}</p>
      <p>{`Present the results `}<strong parentName="p">{`cleanly`}</strong>{` and with the `}<strong parentName="p">{`least contrivance`}</strong>{` to get closer to the truth. `}</p>
    </Process>
    <p>{`Marc talks about a 4-dimensional game he designed, where the mechanic is a button you can press
to switch one of the dimensions. This dimensionality results in strange and wonderful connections
between universes, such that you can pass through walls, or "climb" to rewards on them you
wouldn't otherwise be able to reach.`}</p>
    <p>{`It's worth remembering `}<a parentName="p" {...{
        "href": "/learn/module-1/joyful-subversion/#shifting-the-nature-of-work"
      }}>{`May Li Khoe and Khan Academy`}</a>{`. Great mechanism design revolves around small nuggets of truth, packaged with the least contrivance for people to find and experience themselves, much like negative numbers granting kids super powers when they adventure to the left of the number line, or the visual properties of prime numbers falling out of their work on simple addition and subtraction. `}<strong parentName="p">{`Mechanism design is not about making complex systems! It is about simplifying complex insights`}</strong>{` so that people can learn them intuitively through use (which is joyful), not explicitly through study (which is boring and contrived).`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: True or false: useful mechanisms are inherently complex.`}</p>
        <p>{`False! `}<a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=rI8tNMsozo0&list=PL5ClmaG2tnPO05rr1OOI1liWWrGjvAq_7&index=85"
          }}>{`Simplicity matters`}</a></p>
      </Card>
    </Flash>
    <p>{`Jonathan discusses a puzzle pattern game he made - The Witness - and the "game-feel" reasons
which led him to certain constraints for drawing the solutions to puzzles. These range from ease
of use across platforms to the aesthetic feel that different rules - or the lack of them - induce.
Before he even knew what the puzzles were, he had set up a system which `}<em parentName="p">{`could combine well with
other mechanics`}</em>{`. This is critical: exploration of `}<a parentName="p" {...{
        "href": "/learn/module-1/understanding-ethereum/#virtual-economy"
      }}>{`low-level primitives`}</a>{` `}<strong parentName="p">{`yields possibility`}</strong>{`. `}</p>
    <h3 {...{
      "id": "virtuous-design",
      "style": {
        "position": "relative"
      }
    }}>{`Virtuous design`}<a parentName="h3" {...{
        "href": "#virtuous-design",
        "aria-label": "virtuous design permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`We can define 8 criteria by which we can evaluate the "virtue" of different possibilities we
stumble upon in our design process:`}</p>
    <Process mdxType="Process">
      <p><strong parentName="p">{`Richness`}</strong>{` - adjust the mechanic(s) to get the most interesting consequences. Once you
know you want to switch between 4 dimensions, or solve puzzles on a constrained grid, iterate
over the sub-mechanics. Add terrain to explore in those dimensions, or think about the puzzle
as drawing a partition between different spaces on the constrained grid. `}</p>
      <p><strong parentName="p">{`Completeness`}</strong>{` - are there consequences we haven't found? Jonathan discusses Braid, where
the main mechanic is that you can rewind time, with a sub-mechanic that some objects are immune
to this ability. This sub-mechanic is an `}<em parentName="p">{`exploratory question`}</em>{` and, in order to satisfy the
completeness criterion, it must be applied to every object in the game. That said, there is a
deep balance between "throwing something crazy" at players as they first arrive in the game
world, which impedes their ability to learn; and holding it back entirely, which makes the game
feel incomplete.`}</p>
      <p><strong parentName="p">{`Surprise`}</strong>{` - maximize the amount of information contained within a limited and constrained
package. It is the counterbalancing force to completeness and is created with high level expressions
built from low-level concepts; or by combining game mechanics.`}</p>
      <p><strong parentName="p">{`Lightest Contrivance`}</strong>{` - “the less contrivance you have, the closer to the truth you’ll
get”. This applies to both mechanics and level design. There is an elegance to `}<a parentName="p" {...{
          "href": "https://subconscious.substack.com/p/simple-seeds"
        }}>{`simple rules`}</a>{` and
an ugliness to complex ones. There's also a proportionality here: if you have 2 mechanics, one
of which leads to a good deal of surprising and sublime results, and another which is twice as
complicated and yields the same amount of results, then relative to each other, the second will
`}<em parentName="p">{`feel`}</em>{` ugly.`}</p>
      <p><strong parentName="p">{`Strength of Boundary`}</strong>{` - having done the above, we must now focus in on our surprisingly
elegant consequences and remove mechanics which do not provide enough surprise; are not
relatively as rich as others; do not fit our refined definition of the game we're designing;
create an unfocused space of consequences; or overlap too much.`}</p>
      <p><strong parentName="p">{`Compatibility`}</strong>{` - mechanics need to complement each other, and this idea is confined by
both richness and completeness. Only add mechanics that interact with all aspects of your game.`}</p>
      <p><strong parentName="p">{`Orthogonality`}</strong>{` - basically: your mechanics shouldn't overlap or have similar results. The
example Marc gives is Ikaruga: a shoot-them-up where you can shoot or switch colors, which are
great examples of totally orthogonal principles.`}</p>
      <p><strong parentName="p">{`Generosity`}</strong>{` - this is the consequence of orthogonality and completeness and is `}<em parentName="p">{`about
having mechanics that are not limited to the player`}</em>{`. That is, you can always go into the fourth
dimension, or you can always rewind time: there is nothing that limits you as these are precisely
the mechanics we wish to explore. `}</p>
    </Process>
    <p>{`Though this is specifically about computer game design, we can apply exactly the same kind of
thinking to open networks of distributed trust. As `}<a parentName="p" {...{
        "href": "/learn/module-3/lock-it-open/#the-path-forward"
      }}>{`Juan Benet noted, verifiability is the key mechanic in Web 3`}</a>{`, and instant settlement and collective ownership are examples of interesting sub-mechanics which have not yet been completely explored.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Virtuous design creates valuable truth, which can be assessed with at least 8 criteria. Which of these is most relevant to how we package the truths we find?`}</p>
        <p>{`Lightest contrivance.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "the-value-of-truth",
      "style": {
        "position": "relative"
      }
    }}>{`The value of truth`}<a parentName="h3" {...{
        "href": "#the-value-of-truth",
        "aria-label": "the value of truth permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Jonathan asks: "How do you make a good puzzle?" It's not by making it hard. It's not even by
trying to make it good. It's by `}<strong parentName="p">{`looking for truth and illustrating it`}</strong>{` with the puzzle. `}</p>
    <Process mdxType="Process">
      <p>{`The point of the puzzle is to show some truth.`}</p>
      <p>{`Know what that truth is.`}</p>
      <p>{`Eliminate anything which is not about that truth.`}</p>
    </Process>
    <p>{`The question then becomes, "how do we ensure the player can grasp this truth?" It's not about
brute-force attempts: information needs to build up over each attempt, as it reveals more about
the game mechanics at work. `}</p>
    <blockquote>
      <p parentName="blockquote">{`"The space around the truth is often more valuable than the truth itself."`}</p>
    </blockquote>
    <p>{`Importantly, we're not talking about arbitrary things here: we are talking about principles
which are valuable in and of themselves, outside the game. Great games and great mechanics have
the same quality of unreasonable effectiveness as math does: they are particular illustrations
of general truths. Another way to achieve this is with a `}<strong parentName="p">{`hierarchy of ideas`}</strong>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"When you can have a sequence and there is a pattern along the sequence, a player can have a gradually-dawning surprise, or a sublime growth in understanding, about what the sequence points to."`}</p>
    </blockquote>
    <p>{`This happens regularly and naturally in all games as increasingly complicated mechanics or
combinations are introduced, but it can also "take the form of a conversation about things which
are not quite parallel to the mechanics themselves". Jonathan illustrates this by returning to
the constrained grids of The Witness. Importantly, these non-verbal communications through game
mechanics have to do with `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern"
      }}>{`teaching players a certain pattern`}</a>{`,
and then `}<strong parentName="p">{`getting them to break their perception of that pattern to see higher-order concepts`}</strong>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"By removing anything arbitrary, the designer is abdicating authorship over the puzzle. 'The
Universe' is the real designer of the puzzle. So the puzzle feels more fair, because it's not
about reading the mind of the designer, it's about reading the mind of 'mother nature'."`}</p>
    </blockquote>
    <p>{`If you make a game that encapsulates the truth in some way, then - even if your other design
skills are poor - you will have created something valuable. So, what is the truth you want to
encapsulate with your mechanism, and what is the smallest possible set of rich low-level
questions you can ask which will allow you to explore it completely and come up with something
which is ultimately about generosity?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"When we build games in this way, we're pointing many lenses at the universe to see what's out
there. This is really valuable, because games in this sense are another tool - like mathematics -
in the toolbox that humanity can use to understand the world."`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Great games and great mechanics are particular illustrations of `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`.`}</p>
        <p>{`general truths.`}</p>
      </Card>
    </Flash>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      